import { API_URL } from './constants';
import wretch from 'wretch';

type Role = 'ADMIN' | 'BETA_TESTER';
type User = {
  userName: string;
  isContributor: boolean;
  initiativesLed: string[];
  roles: Role[];
};

export type APIError = {
  type: string;
  message: string;
  title: string;
};

export const userQuery = {
  queryKey: ['me'],
  queryFn: (): Promise<User> => {
    return wretch(`${API_URL}/i/me`)
      .options({ credentials: 'include' })
      .get()
      .json();
  },
};
